import React from "react"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"
import hemneoColors from '../utils/hemneoColors';

const ArticleContainer = styled.div`
  border: 1px solid ${hemneoColors.B6};
  padding: 8px;
`

const ArtiklarPage = () => (
  <Layout>
    <SEO title="Artiklar" />
    <h1>Artiklar</h1>
    <ArticleContainer>
      <h2>Om Hemneo Static</h2>
      <p>
        Hemneo Static är ett experiment för att bland annat utforska för och nackdelar med en bostadsportal byggd som <a href="https://en.wikipedia.org/wiki/Static_web_page">statiska webbsidor</a>, även känt som <a href="https://jamstack.org/">JAMstack</a>.
      </p>

      <p>
        Bostadsannonserna hämtas genom <a href="https://www.hemnet.se">Hemnets</a> publika GraphQL-API (Hemnet API) vid ett tillfälle: när sajten byggs. Därefter finns ingen koppling till Hemnets databas eller API. För att uppdatera bostadsannonserna byggs hela sajten om igen.
      </p>

      <p>
        Att ha statiska sidor istället för dynamiska, med frågor till en databas för varje sidvisning, gör att sajten blir mycket snabbare att ladda och enklare att skala. Nackdelen är istället att sajten måste byggas om när en bostadsannons läggs till, tas bort eller på annat sätt ändras.
      </p>

      <p>
        Sökfunktionen är även den helt frikopplad från Hemnets API, utan bygger på <a href="https://github.com/nextapps-de/flexsearch">FlexSearch.js</a>. Webbläsaren laddar ner ett sökindex och utför sökningen. För den här tillämpningen är denna lösning förmodligen fullständigt vansinnig, men det funkar.
      </p>

      <p>
        Hemneo Static är byggd med <a href="https://www.gatsbyjs.org/">Gatsby</a> som en statisk site och levereras genom <a href="https://zeit.co/home">Zeit Now</a> och <a href="https://zeit.co/smart-cdn">ZEIT Smart CDN</a>
      </p>
    </ArticleContainer>
  </Layout>
)

export default ArtiklarPage
